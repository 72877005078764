.has-error h5 {
    color: #c0392b;
}

.ui-sortable tr {
    cursor: pointer;
    background-color: #F5F5F5;
}

#feature-form .checkbox label{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
}


#feature-form .checkbox label::before{
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

#feature-form .checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555555;
}

#feature-form .checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
    font-family: "FontAwesome";
    content: "\f00c";
}

.help-block{
    margin-bottom: 5px!important;
}

.table-font-size{
    font-size: 14px;
}



/* test style for  */
.container-for-checkbox{
    width: 340px;
}
.align-vertical-line{
    margin-left: 0!important;
}
.self-written-style {
    padding-left: 8px!important;
    padding-right: 13px!important;
    margin-left: 1px;
    margin-right: 10px;
}
@media (max-width: 768px){
    .self-written-style {
        margin-left: 7px;
    }
}
/* end test */


/* Customise tree styles  */
.fuelux .tree .tree-item.tree-selected .tree-item-name, .fuelux .tree .tree-overflow.tree-selected .tree-item-name{
    background-color: #C5C5C5;
    color: #333333;
}

.fuelux .tree.tree-folder-select .tree-branch.tree-selected > .tree-branch-header .tree-branch-name {
    background-color: #C5C5C5;
}

/* end  Customise tree styles  */


