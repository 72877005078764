@font-face {
    font-family: 'bebasRegular';
    src: url('../fonts/BebasNeueCyrillic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bebasBook';
    src: url('../fonts/BebasNeueBookCyrillic.ttf') format('truetype');
    src: url('../fonts/BebasNeueBookCyrillic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

a.btn-primary:focus{
    color: white;
}

a.btn-primary:active{
    color: white;
}

[v-cloak] {
    display: none;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.paging {
    margin: 1px 3px;
}

.margin-left-right-5px {
    margin: 0px 5px;
}

.button_save {
    min-width: 80px;
    max-width: 80px;
}

.profile-logo {
    width: auto;
    height: 400px;
    background: #3498DB;
}

.stat-filter-text {
    font-size: 16px;
}

.stat-filter-text span {
    font-size: 16px;
}

.counterfeit-button-width {
    width: 161px;
}

.cluster-detail-button-width {
    width: 161px;
}

.map-content {
    width: 100%;
    height: 700px;
}

.checkbox-button {
    width: 20px;
    height: 20px;
}

.radio-button {
    width: 10px;
    height: 10px;
}

.counterfeit-link {
    cursor: pointer;
    margin-bottom: 10px;
}

.container-width {
    width: 100%;
}

.city-column {
    font-weight: bold;
    font-size: 11px !important;
}

.login-page-custom {
    width: 450px !important;
}

.month-table-min-width {
    min-width: 1134px;
}

.filters {
    padding-top: 4px;
    margin-bottom: 10px;
}

/* С„РёРєСЃРёСЂРѕРІР°РЅС‹Р№ СЂР°Р·РјРµСЂ РєР°СЂС‚РёРЅРєРё 120px */
.fix-wid-image {
    width: 200px;
}

/* РЎРєСЂС‹С‚РёРµ СЃР°РјРѕР№ СЃС‚Р°РЅРґР°СЂС‚РЅРѕР№ РєРЅРѕРїРєРё */
.load-img {
    position: absolute;
    width: 93%;
    height: 31px;
    cursor: pointer;
    opacity: 0.0;
}

/* СЃС‚РёР»Рё РґР»СЏ "РєРЅРѕРїРєРё" */
.over-load {
    background-color: #2F8CCB;
    text-align: center;
    padding: 7px;
    color: white;
    border-radius: 5px;
    width: 100%;
}

/* СЃРІРѕР№ РѕС‚СЃС‚СѓРї РґР»СЏ Р±Р»РѕРєРѕРІ */
.margin-for-block {
    margin-top: 25px;
}

/* С„РёРєСЃРёСЂРѕРІР°РЅРЅР°СЏ РІС‹СЃРѕС‚Р° Рё Р·Р°РїСЂРµС‚ СЂР°СЃС‚СЏРіРёРІР°С‚СЊ textarea "because I can!" */
.textarea-description {
    height: 120px !important;
    resize: none;
}

/* отцентровка картинки внитри блока */
.block-for-center-img {
    text-align: center;
}

/* стили для второй вкладки */
/* стили для textarea во 2 вкладке (product/form) */
.textarea-description-two {
    height: 210px !important;
    resize: none;
}

.load-img-for-difference {
    top: 0;
    position: absolute;
    width: 93%;
    height: 100%;
    cursor: pointer;
    opacity: 0.0;
}

/* стили для кнопки добавить отличительную черту */
.icon-style-for-button {
    font-size: 20px;
    margin-right: 5px;
}

.add-block-class {
    padding-top: 3px;
    background-color: #2C88C4;
    color: white;
    white-space: normal;
    height: auto;
}

.add-block-class:active {
    background-color: white;
    color: #2C88C4;
}

/* стили label (сканирование) */
/*.fc-event.label-warning, .label-warning {*/
    /*background-color: #3498db;*/
    /*width: 110px;*/
    /*height: 30px;*/
    /*padding-top: 9px;*/
/*}*/

/* число сканирований */
.scan-counter {
    padding: 5px;
    color: #3498db;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    font-weight: bold;
}

.scan-counter-label {
    width: 100%;
}

/* стили для кнопок */
.style-for-button {
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: none;
    border-bottom: 2px solid #2980b9;
    padding: 10px;
    background: #3498DB;
    color: white;
    border-radius: 3px;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
}

.style-for-button:active {
    border-bottom: none;
    margin-top: 2px;
}

/* блок в котором находятся фильтры */
.filter-block-style {
    margin-bottom: 25px;
    margin-top: 15px;
}



/* Стиль для (Фильтр:) */
.style-filter-text {
    padding-top: 25px;
    font-size: 16px;
    color: #3498DB;
    font-weight: bold;

}

/* стили для labels над select */
.style-label-for-select {
    color: #3498DB;
}

/* Start test */
.ios-checkbox {
    vertical-align: top;
    margin: 0 3px 0 0;
    width: 17px;
    height: 17px;
}

.ios-checkbox + label {
    cursor: pointer;
}

/* Прячем оригинальный чекбокс. */
.ios-checkbox:not(checked) {
    position: absolute;
    opacity: 0;
}

.ios-checkbox:not(checked) + label {
    position: relative; /* будем позиционировать псевдочекбокс относительно label */
    padding: 0 0 0 70px; /* оставляем слева от label место под псевдочекбокс */
    color: #3498DB;
    font-weight: bold;
    font-size: 13px;
}

/* Первое состояние */
.ios-checkbox:not(checked) + label:before {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    width: 50px;
    height: 26px;
    border-radius: 13px;
    background: #CDD1DA;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, .2);
}

/* Второе состояние */
.ios-checkbox:not(checked) + label:after {
    content: '';
    position: absolute;
    top: -2px;
    left: 2px;
    width: 22px;
    height: 22px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
    transition: all .2s; /* анимация, чтобы чекбокс переключался плавно */
}

/* Меняем фон чекбокса, когда он включен. */
.ios-checkbox:checked + label:before {
    background: #3498db;
}

/* Сдвигаем переключатель чекбокса, когда он включен. */
.ios-checkbox:checked + label:after {
    left: 26px;
}

/* Показываем получение фокуса. */
.ios-checkbox:focus + label:before {

}

/* Конец теста*/

/* стили для иконок перед текстом  */
.icons-before-text {
    margin-right: 10px;
    font-size: 17px !important;
}

/* стиль для фиксированной высоты  и скрытие текста в случае если его много  */
/* для того чтобы блоки не ехали при разных разрешениях так как они все должны быть*/
/* одного размера и не вставать на разные позиции по горизонтали */
.style-for-hidden {
    overflow: hidden;
    height: 75px;
}

/* стили для отцентровки кнопок (удалить, редактировать) */
.align-button {
    text-align: center;
}

/* отступы и размер самих кнопок */
.form-button {
    margin-right: 5px;
    margin-left: 5px;
    width: 135px;
}

/* эффект нажатия */
.form-button:active {
    border-bottom: none;
    margin-top: 4px;
}

/* стили для страницы partner */

/* стили для текста "фильтр" */
.style-filter-text-partner {
    padding-top: 5px;
    font-size: 16px;
    color: #3498DB;
    font-weight: bold;
}

/* стили для кнопки + эффект нажатия */
.style-for-button-partner {
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: none;
    border-bottom: 2px solid #2980b9;
    background: #3498DB;
    color: white;
    border-radius: 3px;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    white-space: normal;
}

.style-for-button-partner:active {
    border-bottom: none;
    margin-top: 2px;
}

/* стили для иконок в таблице */
.align-icons-partner {
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;
}

/* стили для textarea в модальном окне */
.style-textarea-partner-complaint {
    width: 100%;
    height: 400px;
    resize: none;
}

/* ширина тега td в таблице */
.taken-place-table {
    width: 25%;
}

/* стили для верхней горизонтальной линии */
.heading-table-complaints {
    border-bottom: 1px solid #E7EBEE;
}

/* стили для обрезания текста */
.style-for-trimming {
    margin-top: 12px;
    white-space: nowrap;
    overflow: hidden;
    width: 165px;
    text-overflow: ellipsis;
}

.highcharts-container {
    width: 100% !important;
}

/* стаусы врмени */
.there-answer-instruction {
    background: green;
}

.bigger-tree-days-instruction {
    background: yellow;
}

.bigger-ten-day-instruction {
    background: red;
}

/* стили для памятки к статусам */
.all-instructions {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-bottom: -3px;
    border: 1px solid #344644;
}

.text-instructions {
    display: inline-block;
    margin-left: 5px;
}

/* выравнивание таблицы для телефонов */
@media screen and (max-width: 1430px) {
    /* убираем сужение самой таблицы и добовляем фиксированную ширину чтобы таблица отоброжалась корректно всегда */
    /* (ширина 1430px и ниже!) */
    /* при необходимости сужения добовляйте класс special-table к тегу table */
    /* сужение до 1165px для таблицы в 6 столбцов (6 с половиной) */
    .special-table-6 {
        width: 1165px !important;
        max-width: 100% !important;
        margin-bottom: 20px !important;
    }

    /* сужение до 750px для таблицы в 5 столбцов */
    .special-table-5 {
        width: 1165px !important;
        max-width: 100% !important;
        margin-bottom: 20px !important;
    }
}

/** стили для отображения пункта в черном списке*/
.black-list {
    color: #a22626;
}

.form-control.my-form-control {
    height: auto;
}

.margin-look-product {
    margin-top: 10px;
}

/* стили для логотипа в шапке ( блок с текстом wipon)*/
.style-logo-head {
    margin-top: 5px;
    margin-left: 35px;
    position: absolute;
    width: 70px;
}

/* отступы для картинок внешних признаков подлинности */
.margin-top-bot {
    margin-top: 10px;
    margin-bottom: 10px;
}

/* бордеры для блоков внешних признаков */
.border-for-sign {
    border-top: 2px solid #E7EBEE;
}

/* стили для кнопок */
.style-button-sign {
    white-space: normal;
    text-align: center
}

.bottom-0 {
    padding-bottom: 0;
    margin-bottom: 0;
}

.style-button-filter-block {
    margin-left: 0px !important;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.form-group.required .control-label:after {
    content: "*";
    color: rgb(185, 74, 72);
}

.font-size-16 {
    font-size: 16px;
}

.has-error .select2-selection {
    border: 1px solid rgb(185, 74, 72);
    border-radius: 4px;
}

/* Start test */
.ios-checkbox-mini {
    vertical-align: top;
    margin: 3px 3px 3px 0;
    width: 5px;
    height: 5px;
}

.ios-checkbox-mini + label {
    cursor: pointer;
}

/* Прячем оригинальный чекбокс. */
.ios-checkbox-mini:not(checked) {
    position: absolute;
    opacity: 0;
}

.ios-checkbox-mini:not(checked) + label {
    position: relative; /* будем позиционировать псевдочекбокс относительно label */
    padding: 2px 0 0 47px; /* оставляем слева от label место под псевдочекбокс */
    color: #3498DB;
    font-weight: bold;
    font-size: 13px;
}

/* Первое состояние */
.ios-checkbox-mini:not(checked) + label:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 40px;
    height: 18px;
    border-radius: 12px;
    background: #CDD1DA;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, .2);
}

/* Второе состояние */
.ios-checkbox-mini:not(checked) + label:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
    transition: all .2s; /* анимация, чтобы чекбокс переключался плавно */
}

/* Меняем фон чекбокса, когда он включен. */
.ios-checkbox-mini:checked + label:before {
    background: #3498db;
}

/* Сдвигаем переключатель чекбокса, когда он включен. */
.ios-checkbox-mini:checked + label:after {
    left: 26px;
}

/* Показываем получение фокуса. */
.ios-checkbox-mini:focus + label:before {

}

.filter-sort + label {
    cursor: pointer;
}

.select2-selection__rendered {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.marker-info-table tbody > tr > td {
    padding: 3px 8px;
}

.marker-info-table{
    margin-top: 10px;
    margin-bottom: 5px;
}

.marker-info-block {
    font-size: 14px;
    height: 390px;
    width: 450px;
    font-family: Roboto, Arial, sans-serif;
}

pre {
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
}

.string {
    color: green;
}

.number {
    color: darkorange;
}

.boolean {
    color: blue;
}

.null {
    color: magenta;
}

.key {
    color: red;
}

/* стили для текста "фильтр" */
.style-filter-label-users {
    padding-top: 5px;
    padding-bottom: 8px;
    font-size: 16px;
    color: #3498DB;
    font-weight: bold;
}

.style-filter-input-users {
    padding-left: 5px;
    padding-top: 2px;
    height: 40px;
}

.hide-block {
    display: none;
}

.checkbox-label.checkbox-fuelux:before {
    top: 3px !important;
    margin-top: 3px !important;
}

.relative{
    position:relative;
}

.ajax_loader {
    z-index: 10002;
    /*noinspection CssUnknownTarget*/
    background: url("../../components/ajax_loader_blue.gif") no-repeat center 200px transparent;
}

.borderless td, .borderless th {
    border: none;
}


.not-active {
    color: #8d8687 !important;
    pointer-events: none;
    cursor: not-allowed;
}

iframe.wysihtml5-sandbox{
   width: 100%!important;
}

.vertical-alignment-helper {
    display:table;
    height: 100%;
    width: 100%;
    pointer-events:none;
}
.vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    pointer-events:none;
}
.modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width:inherit;
    height:inherit;
    /* To center horizontally */
    margin: 0 auto;
    pointer-events:all;
}

.header-buttons{
    margin: 10px 0px;
}

.hide-element{
    display: none !important;
}

.sort-icon-active {
    height: 2px;
    display: block;
    padding-bottom: 10px;
}

.sort-icon-not-active {
    height: 2px;
    display: none;
}

#loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #3A3A3A;
    filter:alpha(opacity=50);
    -moz-opacity:0.5;
    -khtml-opacity: 0.5;
    opacity: 0.999;
    z-index: 10000;
    transition: opacity 400ms;
}

.progress-element {
    position: relative;
    width: 60pt;
    margin: -40px auto;
    top: 200px;
}

.progress-element .circle {
    position: absolute;
    width: 1920px;
    max-width: 100%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-name: rotation;
    animation-name: rotation;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.79, 0.22, 0.21, 0.75);
    animation-timing-function: cubic-bezier(0.79, 0.22, 0.21, 0.75);
}

.progress-element .logo {
    position: relative;
    width: 1920px;
    max-width: 100%;
    opacity: 0.99;
    -webkit-animation-name: flash;
    animation-name: flash;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.79, 0.22, 0.21, 0.75);
    animation-timing-function: cubic-bezier(0.79, 0.22, 0.21, 0.75);
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        /*-webkit-transform: rotate(360deg);*/
        /*transform: rotate(360deg);*/
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes flash {
    0% {
        opacity: 0.99;
    }

    50% {
        opacity: 0.1;
    }

    100% {
        opacity: 0.99;
    }
}

@keyframes flash {
    0% {
        opacity: 0.99;
    }

    50% {
        opacity: 0.1;
    }

    100% {
        opacity: 0.99;
    }
}
.is-unread{
    background-color: rgba(238, 238, 238, 0.69);
    font-weight: bold;
}
.is-read{
    background-color: white;
}